





















































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { deleteClient, getClient, saveClient } from '@/api/clients'
import Sticky from '@/components/Sticky/index.vue'
import CustomInputNumber from '@/components/Form/CustomInputNumber.vue'
import ClientProperties from './ClientProperties.vue'
import ClientSearches from './ClientSearches.vue'
import ClientVisits from './ClientVisits.vue'
import { Form } from 'element-ui'
import {
  convertToArrayTranslations,
  confirmDialog,
  successMsg,
  errorMsg,
  validateForm
} from '@/utils'
import { ClientTranslation } from '@/models/ClientTranslation'
import { Client } from '@/models/Client'
import { UserModule } from '@/store/modules/user'
import { AppModule, DeviceType } from '@/store/modules/app'
import { foundFromList, professions } from '@/utils/property'

@Component({
  name: 'ClientDetail',
  components: {
    Sticky,
    ClientVisits,
    ClientSearches,
    ClientProperties,
    CustomInputNumber
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean
  private translations: any = { el: new ClientTranslation({ locale: 'el' }) }
  private loading = false
  private skeletonLoader = false
  private language = 'el'
  private activeName = 'first'
  private professions = professions
  private foundFromOptions = foundFromList
  private client = new Client({
    user_id: UserModule.id,
    office_id: UserModule.officeId
  })

  private atLeastOneLanguage = (rule: any, value: string, callback: Function) => {
    const isValid = !!this.translations[this.language][rule.field]
    return isValid ? callback() : callback(this.$t('form.isRequired'))
  }

  private atLeastOnePhone = (rule: any, value: string, callback: Function) => {
    return this.hasAtLeastOnPhone() ? callback() : callback(this.$t('form.atLeastOnePhone'))
  }

  get pageTitle() {
    return this.isEdit
      ? this.$t('clientDetail.editClient')
      : this.$t('clientDetail.newClient')
  }

  private rules = {
    first_name: [{
      validator: this.atLeastOneLanguage,
      trigger: 'blur'
    }],
    last_name: [{
      validator: this.atLeastOneLanguage,
      trigger: 'blur'
    }],
    main_phone: [{
      validator: this.atLeastOnePhone,
      trigger: 'blur'
    }],
    secondary_phone: [{
      validator: this.atLeastOnePhone,
      trigger: 'blur'
    }],
    main_email: [{
      type: 'email',
      message: this.$t('form.isEmail'),
      trigger: 'blur'
    }],
    found_from: [{
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }],
    tax_identification_number: [{
      min: 9,
      max: 9,
      message: this.$t('form.isVat'),
      trigger: 'blur'
    }]
  }

  created() {
    if (this.isEdit && this.$route.params.id) {
      this.getItem(this.$route.params.id).then(() => {
        this.client.user_id = this.client.user_id || UserModule.id
      })
    }
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private convertToMapTranslation(translations: any) {
    return {
      el: translations.find((translation: any) => {
        return translation.locale === 'el'
      }) || new ClientTranslation({
        locale: 'el'
      })
    }
  }

  private async getItem(id: string) {
    try {
      this.skeletonLoader = true
      const { data } = await getClient({
        id: id
      })
      this.client = data
      this.translations = Object.assign(this.translations, this.convertToMapTranslation(data.translations))
    } catch (err) {
      await errorMsg('api.entityNotFound')
    } finally {
      this.skeletonLoader = false
    }
  }

  private prepareClient() {
    this.client.user = null
    this.client.found_from = this.client.found_from || null
    this.client.profession = this.client.profession || null
    this.client.translations = convertToArrayTranslations(this.translations, ClientTranslation)
  }

  private hasAtLeastOnPhone() {
    return this.client.main_phone || this.client.secondary_phone
  }

  private async deleteItem() {
    const [data] = await confirmDialog('clientList.delete')
    if (!data) return
    try {
      await deleteClient({
        id: this.client.id
      })
      await successMsg('clientList.deleteSuccess')
      return this.$router.push('/client/list')
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private async submitForm() {
    const [valid] = await validateForm(this.$refs.clientForm as Form)
    if (!valid) return errorMsg('form.formErrors')
    this.loading = true
    this.prepareClient()
    try {
      const { data } = await saveClient(this.client)
      if (!this.isEdit) {
        return this.$router.push('/client/edit/' + data.id)
      }
      await successMsg('actions.apiSuccessSave')
    } catch (err) {
      await errorMsg('api.serverError')
    }

    this.loading = false
  }
}
